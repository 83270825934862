import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from '../components/layout'
import CallToAction from "../components/CallToAction"
import { BreadCrumb, MarkdownContent } from "../components/Section"
import generateHTML from '../utils/generateHTML'

const Wrapper = styled.div`
	position: relative;
	padding-top: 250px;
	padding-bottom: 70px;
	max-width: 890px;
	width: 100%;
	margin: auto;
	@media(max-width: 768px){
		padding: 150px 15px 70px;
	}
`

class FullContentTemplate extends React.Component {
	render() {
		const pageData = this.props.data.contentfulContentPage
		return(
			<Layout location={this.props.location}>
				<SEO title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
				<Wrapper>
					<BreadCrumb mTop="120px">Home / <span>{pageData.title}</span></BreadCrumb>
					<h1>{pageData.title}</h1>
					<MarkdownContent dangerouslySetInnerHTML={{__html: generateHTML(pageData.content.content)}} />
				</Wrapper>
				<CallToAction />
			</Layout>
		)
	}
}

export default  FullContentTemplate

export const pageQuery = graphql`
  query fullContentQuery($id: String!) {
  	contentfulContentPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
	      metaDescription
	    }
      content {
        content
      }
	  }
  }
`